/* eslint-disable no-console */
/**
 * Just a very simple logging API that logs to ``console`` by default.
 */
import type {LogFunction, LogHistory, MDC, Severity} from "./types"
import {log_history_to_string_array} from "./default_logger"
import {json_logger} from "./json_logger"

export {log_history_to_string_array}
export type {MDC}

function default_log_function(severity: Severity) {
    return function (message: string, error_or_extra?: Error | {[key: string]: any}) {
        const extra = Object.assign(
            {},
            mdc.get(),
            error_or_extra instanceof Error ? {error: error_or_extra} : error_or_extra,
        )
        if (Object.keys(extra).length > 0) {
            console[severity](message, extra)
        } else {
            console[severity](message)
        }
    }
}

export const log: {
    debug: (message: string, error_or_extra?: Error | {[key: string]: any}) => any
    info: (message: string, error_or_extra?: Error | {[key: string]: any}) => any
    warn: (message: string, error_or_extra?: Error | {[key: string]: any}) => any
    error: (message: string, error_or_extra?: Error | {[key: string]: any}) => any
} = {
    debug: default_log_function("debug"),
    info: default_log_function("info"),
    warn: default_log_function("warn"),
    error: default_log_function("error"),
}

export class DefaultMDC implements MDC {
    private _mdc: {[key: string]: any} = {}

    get(): Readonly<{[p: string]: any}> {
        return this._mdc
    }

    put(extra: {[p: string]: any}): void {
        Object.assign(this._mdc, extra)
    }

    remove(...keys: Array<string>): void {
        for (const key of keys) {
            delete this._mdc[key]
        }
    }
}

export let mdc: MDC = new DefaultMDC()
let _extra: () => {[key: string]: any}
/**
 * Note: The log history is only updated when using the `default` logger.
 */
export const log_history: LogHistory = []

let log_function: LogFunction

export function init(logger: "json" | LogFunction, json_log_function?: (msg: string) => void) {
    if (typeof logger !== "string") {
        log_function = logger
    } else if (!process.env.RUNNING_IN_BROWSER) {
        // Please do not remove the `if (!process.env.RUNNING_IN_BROWSER)` clause above,
        // it will exclude this code block (and therefore the entire json_logging module)
        // when the JavaScript bundle for the browser is created.
        log_function = json_logger(json_log_function || console.log)
        // We override some console methods here to ensure every logged line is in JSON format ...
        const console_method_factory = (severity: Severity) =>
            function (...a: Array<any>) {
                if (!a.length) {
                    return
                }
                let message = a[0]
                if (typeof message === "string" && message.includes("ERROR")) {
                    severity = "error"
                    message = message.substring(6).trim()
                    // Ignore the following error ...
                    if (message.includes("StackdriverTracer#start: Tracing might not work")) {
                        return
                    }
                }
                const extra: any = {}
                const n = a.length
                for (let i = 1; i < n; ++i) {
                    extra["arg" + i] = a[i]
                }
                log_function(severity, message, extra)
            }
        console.debug = console_method_factory("debug")
        console.info = console_method_factory("info")
        console.log = console_method_factory("info")
        console.warn = console_method_factory("warn")
        console.error = console_method_factory("error")
    }
    let _log_log_error = true
    function log_function_factory(severity: Severity) {
        return (message: string, error_or_extra: Error | {[key: string]: any} = {}) => {
            try {
                const extra: any = {}
                if (_extra) {
                    Object.assign(extra, _extra())
                }
                Object.assign(extra, mdc.get())
                if (error_or_extra instanceof Error) {
                    extra.error = error_or_extra
                } else {
                    Object.assign(extra, error_or_extra)
                }
                log_function(severity, message, extra)
            } catch (error) {
                try {
                    if (_log_log_error) {
                        _log_log_error = false
                        log.error(
                            `log.${severity}(...) failed, further logging errors will not be logged`,
                            error,
                        )
                    }
                } catch (ignored) {
                    // Do nothing -- a log function should never fail.
                }
            }
        }
    }
    log.debug = log_function_factory("debug")
    log.info = log_function_factory("info")
    log.warn = log_function_factory("warn")
    log.error = log_function_factory("error")
}

export function set_extra(extra: () => {[key: string]: any}) {
    _extra = extra
}

export function set_mdc(mdc_: MDC) {
    mdc = mdc_
}

// Errors cannot be stringified because its properties are not enumerable ...
// See https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify
if (!("toJSON" in Error.prototype)) {
    Object.defineProperty(Error.prototype, "toJSON", {
        value: function () {
            const alt = {} as any
            Object.getOwnPropertyNames(this).forEach((key) => {
                let v = this[key]
                if (key === "stack") {
                    v = v
                        .replace(/[(][^(]+\/node_modules\//g, "(.../node_modules/")
                        .replace(/[(][^(]+\/packages\//g, "(.../packages/")
                }
                alt[key] = v
            }, this)
            return alt
        },
        configurable: true,
        writable: true,
    })
}
