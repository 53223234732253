import {cleanupOutdatedCaches, createHandlerBoundToURL, precacheAndRoute} from "workbox-precaching"
import {NavigationRoute, registerRoute} from "workbox-routing"
import {clientsClaim} from "workbox-core"
import type {PrecacheEntry} from "workbox-precaching/_types"
import {init as init_logging, log, log_history} from "@cling/lib.shared.logging"
import {
    default_logger,
    log_history_to_string_array,
    safe_extra,
} from "@cling/lib.shared.logging/default_logger"
import type {
    MessageToServiceWorker,
    SendShareDataMessage,
    RefreshImageMessage,
} from "./message_types"
import {error_to_string} from "@cling/lib.shared.utils/to_string"
import {safe_local_storage} from "@cling/lib.web.utils/safe_storage"
import {broken_image_svg, supported_thumbnail_dims} from "@cling/lib.shared.faas/constants"
import {
    SWQueryThumbnailRequest,
    SWQueryThumbnailURLRequest,
    SWQueryThumbnailURLResponse,
    SWThumbnailImageType,
} from "./service_worker_model"
import {BlobUID} from "@cling/lib.shared.model/types"
import {init as init_push_notification} from "./service_worker_push_notifications"

// Disable logging (https://bit.ly/3uRjZVf)
;(self as any).__WB_DISABLE_DEV_LOGS = true

declare const self: ServiceWorkerGlobalScope
/** Provided by build.client.web_app.ts. */
declare const process: {
    env: {
        NODE_ENV: "development" | "test" | "production"
        F_LOG_CONTEXT: string
        F_PRINT_TO_CONSOLE: boolean
    }
}
declare const __WB_MANIFEST: Array<PrecacheEntry>
declare const cling: any

let share_data_promise: undefined | Promise<FormData>
let shared_files: undefined | Array<File>

// TODO: Remove both after 2024-02-01
caches.delete("thumbnails").catch(() => {
    // Ignore
})
caches.delete("thumbnails2").catch(() => {
    // Ignore
})

const log_fn = default_logger({
    log_history,
    print_to_console: process.env.F_PRINT_TO_CONSOLE || safe_local_storage.getItem("__debug"),
    context: process.env.F_LOG_CONTEXT,
})

init_logging(async (severity, message, extra) => {
    const now = Date.now()
    if (extra) {
        extra.__now = now
    } else {
        extra = {__now: now}
    }
    log_fn(severity, message, extra)
    // We want to have the log message in the `log_history` of all browser windows too.
    ;(await self.clients.matchAll()).forEach((client) => {
        client.postMessage({
            type: "log",
            severity,
            context: "ServiceWorker",
            message,
            extra: safe_extra(extra!),
        })
    })
})
;(self as any).cling = {
    debug: () => {
        // eslint-disable-next-line no-console
        log_history_to_string_array(log_history).forEach((line) => console.log(line))
        cling.print_to_console = true
        safe_local_storage.setItem("__debug", "1")
    },
}

// Listen for push events.
init_push_notification()

// Take control of all pages within the scope.
clientsClaim()
// Note: When the web app is built "__WB_MANIFEST" will be replaced with an array literal
//       containing the actual precache manifest -- see https://bit.ly/wb-precache.
precacheAndRoute(__WB_MANIFEST)
cleanupOutdatedCaches()
const respond_with_html = createHandlerBoundToURL("/c/dist/index.html")
registerRoute(
    new NavigationRoute(respond_with_html, {
        allowlist: [/\/c($|\?|\/)/],
        denylist: [/\/c\/dist/, /\/c\/static/, /mode=/],
    }),
)
registerRoute(
    ({url}) => {
        const {hostname, pathname} = url
        return (
            (hostname.endsWith("cling.com") || hostname === "localhost") &&
            pathname === "/c/share_target"
        )
    },
    (options) => {
        share_data_promise = (options.request as Request).formData()
        shared_files = []
        return respond_with_html(options)
    },
    "POST",
)
registerRoute("/sw/shared_file", async () => {
    const shared_file = shared_files?.[0]
    return shared_file
        ? new Response(shared_file, {
              headers: {
                  "Content-Type": shared_file.type || "application/octet-stream",
                  "Content-Length": "" + shared_file.size,
              },
          })
        : new Response("", {status: 404})
})

class ThumbnailData {
    blob_uid: BlobUID
    max_thumbnail_width: number
    max_thumbnail_height: number
    image_type: SWThumbnailImageType

    constructor(args: {
        blob_uid: BlobUID
        max_thumbnail_width: number
        max_thumbnail_height: number
        image_type: SWThumbnailImageType
    }) {
        this.blob_uid = args.blob_uid
        this.max_thumbnail_width = args.max_thumbnail_width
        this.max_thumbnail_height = args.max_thumbnail_height
        this.image_type = args.image_type
    }

    get cache_key(): string {
        // Important: This must match the cache key in `packages/lib.web.worker/preload_media.ts`.
        return `/${this.blob_uid}/${this.max_thumbnail_width}x${this.max_thumbnail_height}`
    }

    get url(): string {
        return `${location.origin}/query-thumbnail?q=${new SWQueryThumbnailRequest(
            this,
        ).to_b64url()}`
    }
}

registerRoute(
    ({url, sameOrigin}) => url.pathname === "/query-thumbnail" && sameOrigin,
    async ({request}) => {
        const thumbnail_data = extract_thumbnail_data(new URL(request.url))
        return await query_thumbnail(thumbnail_data)
    },
    "GET",
)

async function query_thumbnail(thumbnail_data: ThumbnailData): Promise<Response> {
    const cache = await caches.open("thumbnails3")
    const cached_response = await cache.match(thumbnail_data.cache_key)
    if (cached_response) {
        const res = await deref_blob_response_if_necessary(thumbnail_data, cached_response)
        if (res) {
            return res
        }
    }
    let fallback_res: Response | undefined
    let fallback_pixel_count = 0
    const ideal_pixel_count =
        thumbnail_data.max_thumbnail_width * thumbnail_data.max_thumbnail_height
    for (const max_thumbnail_width of supported_thumbnail_dims) {
        for (const max_thumbnail_height of supported_thumbnail_dims) {
            const candidate = new ThumbnailData({
                ...thumbnail_data,
                max_thumbnail_width,
                max_thumbnail_height,
            })
            const fallback_candidate = await cache.match(candidate.cache_key)
            if (!fallback_candidate) {
                continue
            }
            if (
                fallback_pixel_count >= ideal_pixel_count &&
                fallback_pixel_count < max_thumbnail_width * max_thumbnail_height
            ) {
                // We already have a fallback thumbnail, which is better than the current candidate.
                continue
            }
            fallback_res = fallback_candidate
            fallback_pixel_count = max_thumbnail_width * max_thumbnail_height
        }
    }
    fallback_res = await deref_blob_response_if_necessary(thumbnail_data, fallback_res)
    if (navigator.onLine === false) {
        if (fallback_res) {
            return fallback_res
        }
        return broken_image_response()
    }
    const fetch_and_cache_promise = fetch_and_cache(thumbnail_data)
    if (fallback_res) {
        if (
            fallback_pixel_count <
            thumbnail_data.max_thumbnail_width * thumbnail_data.max_thumbnail_height
        ) {
            // Only fetch the thumbnail if it is smaller than the fallback thumbnail.
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            fetch_and_cache_promise.then(() => {
                ;(async () => {
                    ;(await self.clients.matchAll()).forEach((client) => {
                        client.postMessage({
                            type: "refresh_image",
                            url: thumbnail_data.url,
                            blob_uid: thumbnail_data.blob_uid,
                        } satisfies RefreshImageMessage)
                    })
                })().catch((error) => {
                    log.error(
                        "Failed to post message to clients - clients will continue to show the fallback image",
                        {error, thumbnail_data},
                    )
                })
            })
        }
        return fallback_res
    }
    try {
        return await fetch_and_cache_promise
    } catch (error) {
        log.error("Failed to fetch thumbnail - returning broken image", {error, thumbnail_data})
        return broken_image_response()
    }
}

function broken_image_response(): Response {
    return new Response(broken_image_svg, {
        headers: {
            "Content-Type": "image/svg+xml",
            "Content-Length": `${broken_image_svg.length}`,
        },
    })
}

async function fetch_and_cache(data: ThumbnailData) {
    // PERF: Query the thumbnail URL first and then read directly from GCS. This avoids the
    //       streaming through the `query-thumbnail` FAAS.
    const url_res = await fetch_with_retry(
        new Request(
            `https://eu-run.${
                location.hostname
            }/f/query-thumbnail-url?q=${new SWQueryThumbnailURLRequest(data).to_b64url()}`,
            {credentials: "include"},
        ),
        {attempts: 15, backoff: 257},
    )
    if (url_res.status === 204) {
        // The thumbnail could not be created (see `http_query_thumbnail_url`).
        return broken_image_response()
    }
    const url_res_data = await url_res.arrayBuffer()
    const {url, resolved_blob_uid} = SWQueryThumbnailURLResponse.from_buffer(url_res_data)
    const cache = await caches.open("thumbnails3")
    let resolved_blob_data: ThumbnailData | undefined
    if (resolved_blob_uid !== data.blob_uid) {
        resolved_blob_data = new ThumbnailData({...data, blob_uid: resolved_blob_uid})
        const res = await cache.match(resolved_blob_data.cache_key)
        if (res) {
            // We already have a thumbnail for the resolved blob UID. Store a redirect in the
            // cache.
            await cache.put(data.cache_key, blob_ref_response(resolved_blob_uid))
            return res
        }
    }
    const res = await fetch_with_retry(new Request(url, {credentials: "include"}), {
        attempts: 15,
        backoff: 257,
    })
    if (resolved_blob_data) {
        await cache.put(resolved_blob_data.cache_key, res.clone())
        await cache.put(data.cache_key, blob_ref_response(resolved_blob_uid))
    } else {
        await cache.put(data.cache_key, res.clone())
    }
    return res
}

function blob_ref_response(resolved_blob_uid: BlobUID): Response {
    return new Response("", {
        status: 200,
        headers: {"x-cling-resolved-blob-uid": resolved_blob_uid},
    })
}

async function deref_blob_response_if_necessary(
    data: ThumbnailData,
    res?: Response,
): Promise<Response | undefined> {
    if (res?.headers.has("x-cling-resolved-blob-uid")) {
        // This is a blob reference.
        const resolved_blob_uid = res.headers.get("x-cling-resolved-blob-uid")! as BlobUID
        return await query_thumbnail(new ThumbnailData({...data, blob_uid: resolved_blob_uid}))
    }
    return res
}

function extract_thumbnail_data(url: URL): ThumbnailData {
    const q = url.searchParams.get("q")!
    const req = SWQueryThumbnailRequest.from_b64url(q)
    return new ThumbnailData({...req})
}

async function fetch_with_retry(
    request: Request,
    opts: {attempts: number; backoff: number},
): Promise<Response> {
    try {
        const res = await fetch(request.clone())
        if (res.status >= 400) {
            // We also catch 4xx errors here, because a blob might not be available on the
            // server yet, which might cause 404s or 403s.
            throw new Error(`Request to ${request.url.toString()} failed: ${res.status}`)
        }
        return res
    } catch (error) {
        if (opts.attempts === 0) {
            throw error
        }
        await new Promise((resolve) => setTimeout(resolve, opts.backoff))
        return await fetch_with_retry(request, {
            attempts: opts.attempts - 1,
            backoff: Math.min(5000, opts.backoff * 2),
        })
    }
}

self.addEventListener("message", async (event) => {
    const port2 = event.ports?.[0]
    try {
        if (event.data.eventType) {
            // This is a message from GIP - we ignore it.
            return
        }
        const message = event.data as MessageToServiceWorker
        if (message.type === "probe") {
            // If somehow the old web app sends a "probe" message to service_worker_2.js,
            // we respond with a message, which will lead to a reload.
            port2.postMessage({
                message_types_version: 666,
            })
        } else if (message.type === "request_share_data") {
            port2.postMessage(await get_share_data())
        } else if (message.type !== "new_dataflow") {
            is_never_compile_time_check(message)
            log.debug("Ignoring unknown message", {message})
        }
    } catch (error) {
        log.error(error)
    } finally {
        port2?.close()
    }
})
self.addEventListener("install", () => {
    // Eagerly create the thumbnail cache so that `preload_media` will
    // start - well - preloading media.
    caches.open("thumbnails3").catch((error) => {
        log.error("Failed to open cache `thumbnails3`", {error})
    })
    self.skipWaiting()
})

function is_never_compile_time_check(arg: never): void {
    return arg
}

async function get_share_data(): Promise<SendShareDataMessage> {
    try {
        if (!share_data_promise) {
            throw new Error("No share data")
        }
        const share_data = await share_data_promise
        shared_files = share_data.getAll("files") as Array<File>
        if (shared_files.length > 0) {
            return {type: "send_shared_files", files: shared_files}
        }
        let text = share_data.get("url") || share_data.get("text")
        if (typeof text === "string") {
            text = text.trim()
            let title = share_data.get("title")
            if (typeof title === "string") {
                title = title.trim()
            } else {
                title = ""
            }
            if (text.startsWith("http://") || text.startsWith("https://")) {
                return {type: "send_shared_link", url: text, title}
            }
            return {type: "send_shared_note", title, text}
        }
        throw new Error("Unexpected FormData")
    } catch (error) {
        return {type: "error", error: error_to_string(error)}
    }
}
