import {log} from "@cling/lib.shared.logging"
import {SWPushNotification} from "./service_worker_model"
import {PushMessageReceived, PushNavigateMessage} from "./message_types"
import type {PushNotificationStruct} from "@cling/lib.shared.model"

declare const self: ServiceWorkerGlobalScope

export function init() {
    self.addEventListener("push", handle_push_event)
    self.addEventListener("notificationclick", handle_notification_click)
}

function handle_push_event(event: PushEvent) {
    if (!event.data) {
        log.warn("Received push event without data")
        return
    }
    const notification = SWPushNotification.from_b64url(event.data.text()).kind
    log.info("Received push event", notification)
    const promise = (async () => {
        log.info("Sending push event to all clients", notification)
        // Send message to all clients. This is used in tests.
        for (const client of await self.clients.matchAll()) {
            client.postMessage({
                type: "push_message_received",
                notification: {...notification} as unknown as PushNotificationStruct,
            } as PushMessageReceived)
        }
        log.info("Showing notification", notification)
        await self.registration.showNotification(
            notification.title,
            {
                body: notification.body,
                icon: "/c/static/icon_512.png",
                badge: "/c/static/icon_badge_512.png?v=3",
                // Note: iOS and MacOS do not support images in notifications.
                image: notification.image_url,
                data: {
                    board_uid: notification.board_uid,
                    card_uid: notification.card_uid,
                    is_comment: notification.is_comment,
                },
            } as NotificationOptions, // TS doesn't know about the `image` property
        )
    })()
    event.waitUntil(promise)
}

function handle_notification_click(event: NotificationEvent) {
    const promise = (async () => {
        // If that doesn't work, open a new window.
        event.notification.close()
        const clients = await self.clients.matchAll({type: "window"})
        const client_showing_any_board = clients.find((x) => x.url.includes("cling.com/c"))
        if (client_showing_any_board) {
            try {
                await client_showing_any_board.focus()
                log.info("Navigating to board in existing window", {
                    url: client_showing_any_board.url,
                })
                client_showing_any_board.postMessage({
                    type: "push_navigate",
                    board_uid: event.notification.data.board_uid,
                    card_uid: event.notification.data.card_uid,
                    open_comments: event.notification.data.is_comment,
                } satisfies PushNavigateMessage)
                return
            } catch (error) {
                log.warn("Failed to focus existing window - opening new window", error)
            }
        }
        let board_url = `/c/${event.notification.data.board_uid}`
        if (event.notification.data.card_uid) {
            board_url += `/${event.notification.data.card_uid}`
            if (event.notification.data.is_comment) {
                board_url += "/comments"
            }
        }
        log.info("Opening new window", {board_url})
        await self.clients.openWindow(board_url)
    })()
    event.waitUntil(promise)
}
