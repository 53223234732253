export const supported_thumbnail_dims = [128, 256, 512, 1024, 1920] as readonly number[]

export const most_common_thumbnail_dims = [
    [128, 128],
    [1024, 512],
    [1920, 1920],
] as readonly number[][]

export const broken_image_svg =
    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21,5V11.59L18,8.58L14,12.59L10,8.59L6,12.59L3,9.58V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5M18,11.42L21,14.43V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V12.42L6,15.41L10,11.41L14,15.41" style="fill:red"/></svg>'
