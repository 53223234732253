class SafeStorage implements Storage {
    private wrapped!: Storage

    constructor(storage_provider: () => Storage) {
        try {
            this.wrapped = storage_provider()
        } catch {
            // Ignored.
        }
    }

    getItem(key: string): string | null {
        try {
            return this.wrapped.getItem(key)
        } catch {
            return null
        }
    }
    key(index: number): string | null {
        try {
            return this.wrapped.key(index)
        } catch {
            return null
        }
    }
    get length(): number {
        try {
            return this.wrapped.length
        } catch {
            return 0
        }
    }
    removeItem(key: string): void {
        try {
            this.wrapped.removeItem(key)
        } catch {
            // Ignored.
        }
    }
    setItem(key: string, value: string): void {
        try {
            this.wrapped.setItem(key, value)
        } catch {
            // Ignored.
        }
    }
    clear(): void {
        try {
            this.wrapped.clear()
        } catch {
            // Ignored.
        }
    }
    [Symbol.iterator]() {
        return Object.keys(this.wrapped)[Symbol.iterator]()
    }
}

export const safe_session_storage = new SafeStorage(() => sessionStorage)
export const safe_local_storage = new SafeStorage(() => localStorage)
