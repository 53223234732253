import type {LogFunction, LogHistory, Severity} from "./types"
import {class_name, error_to_string} from "@cling/lib.shared.utils/to_string"

declare const cling: any

export function safe_extra(extra: {[key: string]: any}) {
    const res: {[key: string]: any} = {}
    for (const [key, value] of Object.entries(extra)) {
        if (key.startsWith("__")) {
            continue
        }
        if (["string", "number", "boolean"].includes(typeof value) || value instanceof Date) {
            res[key] = value
        } else if (value instanceof Error) {
            res[key] = error_to_string(value)
        } else if (value) {
            res[key] = `<${class_name(value)}>`
        }
    }
    return res
}

export function default_logger({
    context,
    print_to_console: print_to_console_,
    log_history,
}: {
    context?: string
    print_to_console: any
    log_history: LogHistory
}): LogFunction {
    let last_message: string
    let last_message_counter = 0

    function add_to_log_history(
        timestamp: number,
        severity: Severity,
        message: string,
        extra?: {[key: string]: any},
    ) {
        try {
            // Remove escape codes.
            // eslint-disable-next-line no-control-regex
            message = message.replace(/\x1b\[[0-9]+m/g, "")
            if (severity === "warn" && !message.includes("WARN")) {
                message = "WARN: " + message
            } else if (severity === "error" && !message.includes("ERROR")) {
                message = "ERROR: " + message
            }
            if (extra) {
                const a = [] as string[]
                for (const [key, value] of Object.entries(safe_extra(extra))) {
                    a.push(`${key}: ${JSON.stringify(value)}`)
                }
                if (a.length) {
                    message += ` {${a.join(", ")}}`
                }
            }
            if (log_history.length && timestamp < log_history[log_history.length - 1].timestamp) {
                // The given `timestamp` is *before* the timestamp of the last log entry.
                let i = log_history.length - 1
                while (i > 0 && timestamp < log_history[i - 1].timestamp) {
                    --i
                }
                log_history.splice(i, 0, {timestamp, message})
                if (
                    last_message_counter > 1 &&
                    log_history[log_history.length - 1].message === last_message
                ) {
                    // Adapt `last_message_counter`.
                    last_message_counter = 1
                    while (
                        last_message_counter < 3 &&
                        log_history.length - (last_message_counter + 1) >= 0 &&
                        log_history[log_history.length - (last_message_counter + 1)].message ===
                            last_message
                    ) {
                        last_message_counter += 1
                    }
                }
                return
            }
            if (message === last_message) {
                last_message_counter += 1
                if (last_message_counter >= 4) {
                    if (last_message_counter === 4) {
                        log_history.splice(log_history.length - 2, 2)
                    }
                    const x = log_history[log_history.length - 1]
                    x.timestamp2 = timestamp
                    x.message = `(${last_message_counter}) ${last_message}`
                    return
                }
            } else {
                last_message = message
                last_message_counter = 1
            }
            log_history.push({timestamp, message})
        } finally {
            if (log_history.length > 500) {
                log_history.splice(0, log_history.length - 500)
            }
        }
    }

    function log_function(severity: Severity, message: string, extra?: {[key: string]: any}) {
        const now = extra?.__now || Date.now()
        let print_to_console = print_to_console_
        // If `cling.print_to_console` is defined, it takes precedence over the
        // `print_to_console` option specified, when the default_logger was created.
        if (typeof cling !== "undefined" && typeof cling.print_to_console === "boolean") {
            print_to_console = cling.print_to_console
        }
        if (extra) {
            for (const name of Object.keys(extra)) {
                if (name.startsWith("__")) {
                    delete extra[name]
                }
            }
            if (!Object.keys(extra).length) {
                extra = undefined
            }
        }
        if (print_to_console) {
            const c = console as any
            const time = time_prefix(now)
            let s = time
            if (context) {
                s += " " + context
            }
            s += " " + message
            if (extra) {
                c[severity].call(c, s, extra)
            } else {
                c[severity].call(c, s)
            }
        }
        if (log_history) {
            add_to_log_history(now, severity, message, extra)
        }
    }

    return log_function
}

export function log_history_to_string_array(
    log_history: LogHistory,
    {max_size}: {max_size?: number} = {},
): Array<string> {
    let a = log_history.map(({timestamp: t, timestamp2: t2, message: m}) =>
        t2 ? `${time_prefix(t)} - ${time_prefix(t2)} ${m}` : `${time_prefix(t)} ${m}`,
    )
    if (max_size && a.length > 0) {
        let i = a.length - 1
        let size = a[i].length
        while (i > 0 && size + a[i - 1].length <= max_size) {
            i -= 1
            size += a[i].length
        }
        if (i > 0) {
            a = a.slice(i)
        }
    }
    return a
}

function time_prefix(now: number): string {
    const d = new Date(now)
    const pad2 = (n: number) => (n < 10 ? "0" : "") + n
    const pad3 = (n: number) => (n < 100 ? "0" : "") + pad2(n)
    const hh = pad2(d.getUTCHours())
    const mm = pad2(d.getUTCMinutes())
    const ss = pad2(d.getUTCSeconds())
    const sss = pad3(d.getMilliseconds())
    return `${hh}:${mm}:${ss}.${sss}Z`
}
